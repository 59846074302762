@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');

body {
  margin: 0;
}

.hero {
  background-image: url("img/mikko-alavuo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 100vh;
}

.bg-wrapper {
  background-color: rgba(0, 0, 0, 0.67);
  max-width: 415px;
  min-width: 160px;
  width: 45vw;
  height: 100vh;
  position: absolute;
  right: 52%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.link {
  color: #fff;
  text-decoration: none;
  transition: color .5s ease;
  font-size: 0.9em;
  line-height: 1.5em;
  font-family: 'Roboto Condensed', sans-serif;
}

.link:hover {
  color: #ddd;
  text-decoration: none;
  transition: color .5s ease;
}

h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2.7em;
  margin: 1.2em 0 0 0;
  line-height: 1em;
  text-align: center;
  color: #fff;
}

h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  margin: 0.3em 0 0.8em;
  text-align: center;
  line-height: 1em;
  color: #fff;
}